<template>
  <!-- Table Container Card -->
  <b-card no-body>

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Entries</label>
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
            :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <b-button variant="primary" @click="openUserModal">
            + Add
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table ref="refUserListTable" :items="fetchUsers" responsive :fields="tableColumns" primary-key="id"
      :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc"
      class="position-relative">

      <template #cell(name)="data">
        <span class="text-nowrap">
          {{ `${data.item.attributes.firstName} ${data.item.attributes.lastName}` }}
        </span>
      </template>

      <template #cell(role)="data">
        <span class="text-nowrap">
          {{ `${data.item.attributes.role.attributes.displayName}` }}
        </span>
      </template>

      <template #cell(hospital)="data">
        <span class="text-nowrap">
          {{
            data.item.attributes.client ? `${data.item.attributes.client.attributes.name}
                    ${data.item.attributes.client.attributes.department}` : ""
          }}
        </span>
      </template>

      <template #cell(e-mail)="data">
        <span class="text-nowrap">
          {{ `${data.item.attributes.username}` }}
        </span>
      </template>

      <template #cell(phone)="data">
        <span class="text-nowrap">
          {{ `${data.item.attributes.phone}` }}
        </span>
      </template>

      <template #cell(baseCapacity)="data">
        <span class="text-nowrap">
          {{ data.item.attributes.capacity ? `${data.item.attributes.capacity} mhr/month` : "" }}
        </span>
      </template>


      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">

          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>
          <b-dropdown-item @click="onEdit(data)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="onDelete(data)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

          <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <div>
      <b-sidebar id="sidebar-add-new-event" sidebar-class="sidebar-xl" :visible="showModal" bg-variant="white" shadow
        backdrop no-header right @change="onCloseModal">
        <template #default="{ hide }">
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
              {{ user.id ? 'Edit' : 'Add' }}
            </h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>

          <!-- Body -->
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">

            <!-- Form -->
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

              <!-- Media -->
              <b-media class="mb-2">
                <template #aside>
                  <b-avatar ref="previewEl" :src="user.avatar" size="90px" v-on:click.native="$refs.refInputEl.click()" />
                </template>
                <h4 class="mb-1">
                  {{ user.fullName }}
                </h4>
                <div class="w-100">
                  <validation-provider #default="validationContext" name="Role" rules="required">
                    <b-form-group label="Role:" label-for="role">
                      <!-- <b-form-input id="role" v-model="user.role" autofocus
                        :state="getValidationState(validationContext)" trim /> -->
                      <b-form-select id="role" v-model="user.role" :options="roles"
                        :state="getValidationState(validationContext)" class="w-100" />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="d-flex flex-wrap hidden">
                  <b-button variant="primary" @click="$refs.refInputEl.click()">
                    <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer">
                    <span class="d-none d-sm-inline">Update</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                  <b-button variant="outline-secondary" class="ml-1">
                    <span class="d-none d-sm-inline">Cancel</span>
                    <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
                  </b-button>
                </div>
              </b-media>

              <b-row>
                <b-col md="6">
                  <validation-provider #default="validationContext" name="First Name" rules="required">
                    <b-form-group label="First Name:" label-for="first-name">
                      <b-form-input id="first-name" v-model="user.firstName" autofocus
                        :state="getValidationState(validationContext)" trim />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider #default="validationContext" name="Last Name" rules="required">
                    <b-form-group label="Last Name:" label-for="last-name">
                      <b-form-input id="last-name" v-model="user.lastName" autofocus
                        :state="getValidationState(validationContext)" trim />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <validation-provider #default="validationContext" name="E-mail" rules="required">
                    <b-form-group label="E-mail:" label-for="e-mail">
                      <b-form-input id="e-mail" v-model="user.email" autofocus
                        :state="getValidationState(validationContext)" trim />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider #default="validationContext" name="Phone" rules="required">
                    <b-form-group label="Phone:" label-for="phone">
                      <b-form-input id="phone" v-model="user.phone" autofocus
                        :state="getValidationState(validationContext)" trim />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <div v-if="user.role === managerRoleId || user.role === contactRoleId">
                <h3>Related Client</h3>

                <validation-provider #default="validationContext" name="Client" rules="required">
                  <b-form-group label="Add access to:" label-for="client">
                    <!-- <b-form-input id="standard-monthly-capacity" v-model="user.capacity" autofocus
                          :state="getValidationState(validationContext)" trim type="number" /> -->
                    <b-form-select :options="hospitals" v-model="user.client" id="client"
                      :state="getValidationState(validationContext)" />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div v-if="user.role === assistantRoleId">
                <b-row>
                  <b-col md="6">
                    <validation-provider #default="validationContext" name="Standard Monthly Capacity" rules="required">
                      <b-form-group label="Standard Monthly Capacity:" label-for="standard-monthly-capacity">
                        <b-form-input id="standard-monthly-capacity" v-model="user.capacity" autofocus
                          :state="getValidationState(validationContext)" trim type="number" />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider #default="validationContext" name="Hourly Rate" rules="required">
                      <b-form-group label="Hourly Rate:" label-for="hourly-rate">
                        <b-form-input id="hourly-rate" v-model="user.rate" autofocus
                          :state="getValidationState(validationContext)" trim type="number" />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <h3>Address</h3>

                <b-row>
                  <b-col md="6">
                    <validation-provider #default="validationContext" name="City" rules="required">
                      <b-form-group label="City:" label-for="city">
                        <b-form-input id="city" v-model="user.city" autofocus
                          :state="getValidationState(validationContext)" trim />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider #default="validationContext" name="Canton" rules="required">
                      <b-form-group label="Canton:" label-for="canton">
                        <b-form-input id="canton" v-model="user.canton" autofocus
                          :state="getValidationState(validationContext)" trim />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <validation-provider #default="validationContext" name="PLZ" rules="required">
                      <b-form-group label="PLZ:" label-for="plz">
                        <b-form-input id="plz" v-model="user.plz" autofocus :state="getValidationState(validationContext)"
                          trim />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider #default="validationContext" name="Street" rules="required">
                      <b-form-group label="Street:" label-for="street">
                        <b-form-input id="street" v-model="user.street" autofocus
                          :state="getValidationState(validationContext)" trim />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <h3>Client access</h3>
                <b-form-group label="Add access to:">
                  <b-form-select v-model="hospital" :options="hospitals" />
                </b-form-group>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addAccess">
                  + Add access
                </b-button>

                <div class="list mb-2 mt-1">
                  <b-row v-for="entity in selectedHospitals" :key="entity.value" class="list-item">
                    <b-col md="10">{{ entity.text }}</b-col>
                    <b-col md="2" class="text-right">
                      <feather-icon icon="Trash2Icon" size="16" @click="selectedHospitals.splice(index, 1)" class="" />
                    </b-col>
                  </b-row>
                </div>

                <h3>Skills and Bio</h3>
                <b-row>
                  <b-col md="8">
                    <b-form-group label="Select Skill:" label-for="skill">
                      <b-form-input id="skill" autofocus trim v-model="skillInputVal" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="w-100 mt-2"
                      @click="addSkill">
                      + Add Skill
                    </b-button>
                  </b-col>
                </b-row>

                <div class="list">
                  <b-row v-for="(skill, index) in skills" :key="skill" class="list-item">
                    <b-col md="10">{{ skill }}</b-col>
                    <b-col md="2" class="text-right">
                      <feather-icon icon="Trash2Icon" size="16" class="" @click="skills.splice(index, 1)" />
                    </b-col>
                  </b-row>
                </div>
              </div>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                  {{ user.id ? 'Update' : 'Add ' }}
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                  Reset
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
    </div>

    <b-modal id="modal-scoped">
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
        <h5>Modal Header</h5>
      </template>

      <template #default="{ hide }">
        <p>Modal Body with button</p>
        <b-button @click="hide()">Hide Modal</b-button>
      </template>

      <template #modal-footer="{ ok, cancel, hide }">
        <b>Custom Footer</b>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="ok()">
          OK
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Forget it
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BFormCheckbox, BFormTextarea, BFormSelect
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Parse from "parse/dist/parse.min.js";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import useUserList from './useUserList'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { required, email, url } from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BACKEND_URL } from '../../../utils/constants'
import axios from "@axios";

// import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSidebar,
    ValidationProvider,
    ValidationObserver, BForm, BFormGroup, BFormInvalidFeedback, BFormCheckbox, BFormTextarea, flatPickr, BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showModal: false,
      // user: { extendedProps: {} }
      skills: [],
      skillInputVal: "",
      hospital: "",
      hospitals: [],
      hospitalObjs: [],
      selectedHospitals: [],
      roles: [],
      user: {},
      assistantRoleId: "",
      managerRoleId: "",
      contactRoleId: "",
    }
  },
  props: {
  },
  setup(props) {
    // const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })


    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      statusFilter,

      refetchData,

      resolveUserStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useUserList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
      resetObserver
    } = formValidation(null, null)

    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { previewEl.value.src = base64 })

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveUserStatusVariantAndIcon,
      resolveClientAvatarVariant,

      getValidationState,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      resetForm,
      clearForm,
      resetObserver,
      refFormObserver
    }
  },
  methods: {
    openUserModal() {
      this.showModal = true
    },
    onCloseModal(val) {
      this.showModal = val


      if (!val) {
        this.user = {}
        this.skills = []
        this.selectedHospitals = []
      }
      this.resetObserver();
    },
    async onSubmit() {
      const User = Parse.Object.extend("User");

      let user = new User();
      if (this.user.id) {
        const query = new Parse.Query(User);
        user = await query.get(this.user.id);
      }
      user.set("email", this.user.email)
      user.set("username", this.user.email)
      user.set("password", this.user.email)
      user.set("lastName", this.user.lastName)
      user.set("firstName", this.user.firstName)
      user.set("phone", this.user.phone)

      const Role = Parse.Object.extend("_Role");

      const role = new Role();
      role.id = this.user.role;

      user.set("role", role)

      if (this.user.role === this.assistantRoleId) {
        user.set("rate", parseInt(this.user.rate))
        user.set("capacity", parseInt(this.user.capacity))
        user.set("skills", this.skills)

        user.set("city", this.user.city)
        user.set("canton", this.user.canton)
        user.set("plz", this.user.plz)
        user.set("street", this.user.street)
      }
      else if (this.user.role === this.managerRoleId || this.user.role === this.contactRoleId) {
        const Client = Parse.Object.extend("Client");
        const client = new Client();
        client.id = this.user.client
        user.set("client", client)
      }

      const acl = new Parse.ACL(Parse.User.current())
      acl.setPublicReadAccess(true)
      acl.setPublicWriteAccess(true)

      user.setACL(acl)

      user.save()
        .then((savedUser) => {
          // Execute any logic that should take place after the object is saved.
          this.showModal = false;

          if (this.user.role === this.assistantRoleId) {

            const clientRelations = savedUser.relation("clients");

            clientRelations.remove(this.hospitalObjs);
            this.selectedHospitals.forEach(hospital => {
              clientRelations.add(this.hospitalObjs.find(h => h.id === hospital.value))
            })

            savedUser.save().then((u) => {
            }).catch(ex => {
            });
          }


          const json_payload = JSON.stringify({
            email: this.user.email
          });
          axios.get(`${BACKEND_URL}?action=passwordSet&data=${json_payload}`).then((response) => {
          });
          this.user = {}
          this.skills = []
          this.selectedHospitals = []
          this.refetchData()
        }, (error) => {
          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error creating a user",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        });
    },
    addSkill() {
      this.skills.push(this.skillInputVal)
      this.skillInputVal = ""
    },
    addAccess() {
      if (this.hospital && !this.selectedHospitals.find(h => h.value === this.hospital)) {
        this.selectedHospitals.push(this.hospitals.find(h => h.value === this.hospital))
        this.hospital = ""
      }
    },
    async loadHospitals() {
      const Client = Parse.Object.extend("Client");
      const query = new Parse.Query(Client);
      const result = await query
        .find()
      this.hospitals = result.map(res => ({
        value: res.id,
        text: `${res.attributes.name} ${res.attributes.department}`
      }))

      this.hospitalObjs = result

    },
    async loadRoles() {
      const Role = Parse.Object.extend("_Role");
      const query = new Parse.Query(Role);
      const result = await query
        .find()
      this.roles = result.map(res => ({
        value: res.id,
        text: `${res.attributes.displayName}`
      }))
      this.assistantRoleId = this.roles.filter(role => role.text === 'Assistant')[0].value
      this.managerRoleId = this.roles.filter(role => role.text === 'Manager')[0].value
      this.contactRoleId = this.roles.filter(role => role.text === 'Contact')[0].value
    },
    async onEdit(data) {

      const r = data.item.relation("clients");
      const clients = await r.query().find();

      this.selectedHospitals = clients.map(res => ({
        value: res.id,
        text: `${res.attributes.name} ${res.attributes.department}`
      }))

      this.user.email = data.item.attributes.username;
      this.user.phone = data.item.attributes.phone;
      this.user.rate = data.item.attributes.rate;
      this.user.firstName = data.item.attributes.firstName;
      this.user.lastName = data.item.attributes.lastName;
      this.user.capacity = data.item.attributes.capacity;
      this.skills = data.item.attributes.skills ?? [];
      this.user.role = data.item.attributes.role.id;
      this.user.id = data.item.id;

      this.user.city = data.item.attributes.city;
      this.user.canton = data.item.attributes.canton;
      this.user.plz = data.item.attributes.plz;
      this.user.street = data.item.attributes.street;
      if (data.item.attributes.client) {
        this.user.client = data.item.attributes.client.id;
      }

      this.showModal = true;
    },
    onDelete(data) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete a user.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            // data.item.destroy([])
            // this.refetchData()
            const json_payload = JSON.stringify({
              userId: data.item.id
            })
            axios.get(`${BACKEND_URL}?action=deleteUser&data=${json_payload}`).then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Deleted User successfully.',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.refetchData()
            });

          }
        })
    }
  },
  created() {
    this.loadHospitals()
    this.loadRoles()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.user-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.list {
  height: 149px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: -2px 2px 4px #00000011;

  .list-item {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
